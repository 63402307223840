import React, { useState } from "react";
import Lottie from "lottie-react";
import { useFormik } from "formik";
import Axios from "axios";

import Concent from "./Concent";
import BasicInfo from "./BasicInfo";
import FacePhoto from "./FacePhoto";
import DocUplaod from "./DocUplaod";
import VoiceRecording from "./VoiceRecording";
import initialValues, { validationSchema } from "./formValidation";
import animationData1 from "../lotties/upload.json";
import animationData2 from "../lotties/submitTick.json";
import Bad_Request from "../lotties/Error_404.json";

import {
  CssBaseline,
  AppBar,
  Box,
  Container,
  Toolbar,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Link,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

const Copyright = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "} {new Date().getFullYear()}
      {"."}
      <Link color="inherit" href="https://aisense.co.in/">
        AiSense LLP
      </Link>
    </Typography>
  );
};

const Checkout = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [submitStatus, setSubmitStatus] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const steps = ["Consent", "BasicInfo", "Image", "Voice", "Treatment"];
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Concent formik={formik} />;
      case 1:
        return <BasicInfo formik={formik} />;
      case 2:
        return <FacePhoto formik={formik} />;
      case 3:
        return <VoiceRecording formik={formik} />;
      case 4:
        return <DocUplaod formik={formik} />;
      default:
        throw new Error("Unknown step");
    }
  };

  const [hide, setHide] = useState([
    true,
    ...Array(steps.length - 1).fill(false),
  ]);
  const handleClick = (index) => {
    const newButtonState = Array(steps.length).fill(false);
    newButtonState[index] = true;
    setHide(newButtonState);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("Form Data:", values);
      formik.resetForm();

      const {
        isConsentObtained,
        internName,
        hospitalName,
        dateTime,
        // patientId,
        age,
        gender,
        hbValue,
        esr,
        lymphocyteValue,
        diagnosis,
        clinicalInfo1,
        facePhoto,
        voiceSample,
        isPatientOnTreatment,
        treatmentDoc,
        investigationReports,
        chestXRay,
        otherDoc,
      } = values;

      try {
        // Send a POST request to the server with the form data
        const response = await Axios.post(
          "https://data.aisense.co.in/api/patients",
          {
            isConsentObtained,
            internName,
            hospitalName,
            dateTime,
            // patientId,
            age,
            gender,
            hbValue,
            esr,
            lymphocyteValue,
            diagnosis,
            clinicalInfo1,
            facePhoto,
            voiceSample,
            isPatientOnTreatment,
            treatmentDoc,
            investigationReports,
            chestXRay,
            otherDoc,
          }
        );

        // Handle the response from the server as needed
        console.log("Server Response:", response.data);
        console.log(response.status);
        setSubmitStatus(response.status);
        // Reset the form
        formik.resetForm();
      } catch (error) {
        console.error("Error:", error);
        console.log(error.response.status);
        setSubmitStatus(error.response.status);
      }
    },
  });

  // const basicInfoRequiredFields = [
  //   "isConsentObtained",
  //   "internName",
  //   "hospitalName",
  //   "age",
  //   "gender",
  //   "isPatientOnTreatment",
  // ];
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h5" color="inherit" noWrap>
            Be Volunteer for Research
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h5" align="center">
            Bio-Signals Sample Collection Form
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{hide[index] ? label : ""}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              {submitStatus === 0 ? (
                <div>
                  <Typography variant="h5" sx={{ textAlign: "center" }}>
                    Uploading...
                  </Typography>
                  <Lottie
                    className="m-auto"
                    style={{ height: "250px", width: "250px" }}
                    animationData={animationData1}
                  />
                </div>
              ) : submitStatus === 200 ? (
                <div>
                  <Typography variant="h5" sx={{ textAlign: "center" }}>
                    Done...
                  </Typography>
                  <Lottie
                    className="m-auto"
                    style={{ height: "250px", width: "250px" }}
                    animationData={animationData2}
                  />
                  <Typography variant="h5" sx={{ textAlign: "center" }}>
                    "We are collecting this data for pure research purposes, and
                    we will not disclose your identity"
                  </Typography>
                  <Typography variant="h5" sx={{ textAlign: "center" }}>
                    Thank you for your valuable contribution to medical
                    research!!
                  </Typography>
                </div>
              ) : (
                <div>
                  <Typography variant="h5" sx={{ textAlign: "center" }}>
                    Something went wrong, please try again...
                  </Typography>
                  <Lottie
                    className="m-auto"
                    style={{ height: "250px", width: "250px" }}
                    animationData={Bad_Request}
                  />
                </div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button
                    onClick={() => {
                      handleBack();
                      handleClick(activeStep - 1);
                    }}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Back
                  </Button>
                )}

                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      handleNext();
                      formik.handleSubmit();
                      const newButtonState = Array(steps.length).fill(false);
                      setHide(newButtonState);
                    }}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={async () => {
                      // Validation

                      if (
                        activeStep === 0 &&
                        formik.values.termsAndConditions === true
                      ) {
                        handleNext();
                        handleClick(activeStep + 1);
                      } else {
                        const errors = await formik.validateForm();

                        if (
                          activeStep === 1 &&
                          Object.keys(errors).length === 2 // todo: change this to 0 and apply logic accordingly
                        ) {
                          console.log(`errors: ${errors}`);
                          handleNext();
                          handleClick(activeStep + 1);
                        } else if (
                          activeStep === 2 &&
                          errors["facePhoto"] === undefined
                        ) {
                          handleNext();
                          handleClick(activeStep + 1);
                        } else if (
                          activeStep === 3 &&
                          errors["voiceSample"] === undefined
                        ) {
                          handleNext();
                          handleClick(activeStep + 1);
                        } else {
                          console.log("Errors");
                          console.log(errors);
                          toast.warning("Please fill all required fields", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                          });
                        }
                      }
                    }}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <Copyright />
      </Container>
    </React.Fragment>
  );
};
export default Checkout;
