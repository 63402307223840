import React, { useState } from "react";
import { Button, Input, IconButton, Typography, Stack } from "@mui/material";
import { AddCircleOutline, Delete } from "@mui/icons-material";
import {
  ref,
  uploadBytes,
  getStorage,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import firebaseApp from "../config/firebaseConfig";

const FileUploadUi = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleFileRemove = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };
  
  const handleFilesUpload = () => {
    selectedFiles.forEach((file) => {
      // upload to the firebase storage
      const storage = getStorage(firebaseApp);
      console.log(`credentials: ${process.env.REACT_APP_STORAGE_BUCKET}`);
      const fileRef = ref(storage, "facePhoto/" + file.name);
      const uploadTask = uploadBytesResumable(fileRef, file);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
              default:
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            // TODO: save the downloadURL to the database
          });
        },
      );
    });
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        id="fileInput"
        multiple
        onChange={handleFileSelect}
      />
      <label htmlFor="fileInput">
        <Button
          variant="contained"
          component="span"
          startIcon={<AddCircleOutline />}
        >
          Select Images
        </Button>
      </label>
      <Stack spacing={2} mt={2}>
        {selectedFiles.map((file, index) => (
          <div key={index}>
            <Typography variant="body1">{file.name}</Typography>
            <IconButton
              size="small"
              color="error"
              onClick={() => handleFileRemove(index)}
              aria-label="delete"
            >
              <Delete />
            </IconButton>
          </div>
        ))}
      </Stack>
      <Button
        variant="contained"
        onClick={handleFilesUpload}
        disabled={selectedFiles.length === 0}
        mt={2}
      >
        Upload Images
      </Button>
    </div>
  );
};

export default FileUploadUi;
