import React from "react";
import { Routes, Route } from "react-router-dom";
import SampleForm from "./VoiceSample/SampleForm";
import FileUploadUi from "./pages/fileUpload";
import SelfieValidate from "./pages/selfieValidate";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checkout from "./template/Checkout";

const App = () => {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Checkout />} />
        <Route path="/upload" element={<FileUploadUi />} />
        <Route path="/selfie" element={<SelfieValidate />} />
        <Route path="/Checkout" element={<Checkout />} />
      </Routes>
    </>
  );
};

export default App;
