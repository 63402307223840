import React, { useState } from "react";
import { Typography, Button } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SelfieValidate from "../pages/selfieValidate";

const FacePhoto = ({ formik }) => {
  const [showSelfieValidate, setShowSelfieValidate] = useState(false);
  const [isCamEnable, setIsCamEnable] = useState(true);
  const [selfieRetake, setSelfieRetake] = useState(false);

  const handleSelfieCapture = (e) => {
    e.preventDefault();
    setShowSelfieValidate(true);
    setIsCamEnable(true);
  };

  const handleHideWebcam = (e) => {
    e.preventDefault();
    setShowSelfieValidate(false);
    setIsCamEnable(false);
  };

  const handleSelfieRetake = (e) => {
    console.log("handleSelfieRetake called");
    e.preventDefault();
    setSelfieRetake(true);
    formik.setValues({ ...formik.values, facePhoto: "" });
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Capture your face image
      </Typography>
      <SelfieValidate
        key={selfieRetake ? "retake" : "capture"}
        formik={formik}
        isCamEnable={isCamEnable}
        handleHideWebcam={handleHideWebcam}
        handleSelfieRetake={handleSelfieRetake}
      />
    </React.Fragment>
  );
};

export default FacePhoto;

// import React, { useState, useRef, useCallback } from "react";
// import Webcam from "react-webcam";
// import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import CachedIcon from "@mui/icons-material/Cached";
// import { useFaceDetection } from "react-use-face-detection";
// import { Camera } from "@mediapipe/camera_utils";
// import FaceDetection from "@mediapipe/face_detection";

// const FacePhoto = ({ formik }) => {
//   const [isWebcamOn, setIsWebcamOn] = useState(true);
//   // const webcamRef = useRef(null);

//   const videoConfig = {
//     width: { ideal: 316 },
//     height: { ideal: 234 },
//     facingMode: "user", // Use the user-facing camera (you can change this as needed)
//     frameRate: { ideal: 15, max: 30 },
//   };

//   const toggleWebcam = () => {
//     setIsWebcamOn((prev) => !prev); // Toggle the webcam state
//   };

//   const capture = useCallback(() => {
//     const imageSrc = webcamRef.current.getScreenshot();
//     formik.setValues({ ...formik.values, facePhoto: imageSrc });
//   });

//   let msg = "";
//   let values = {};
//   // console.log("isCamEnable " + isCamEnable);
//   const { webcamRef, boundingBox, isLoading, detected, facesDetected } =
//     useFaceDetection({
//       faceDetectionOptions: {
//         model: "short",
//         minDetectionSize: 0.2,
//       },
//       faceDetection: new FaceDetection.FaceDetection({
//         locateFile: (file) =>
//           `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
//       }),
//       camera: ({ mediaSrc, onFrame }) =>
//         new Camera(mediaSrc, {
//           onFrame,
//           width: { ideal: 316 },
//           height: { ideal: 234 },
//           facingMode: "user", // Use the user-facing camera (you can change this as needed)
//           frameRate: { ideal: 15, max: 30 },
//         }),
//     });
//   let okk = false;
//   if (facesDetected === 1) {
//     // check if the face is in center of the frame (xCenter and yCenter)
//     // check if the face is close or not (width and height)
//     const { xCenter, yCenter, width, height } = boundingBox[0];
//     // values = { xCenter, yCenter, width, height };
//     if (xCenter > 0.2 && xCenter < 0.6 && yCenter > 0.3 && yCenter < 0.6) {
//       if (width > 0.25 && height > 0.28) {
//         msg = "Face is valid";
//         okk = true;
//       } else {
//         msg = "Face is too small";
//         okk = false;
//       }
//     } else {
//       msg = "Face is not in the center of the frame";
//       okk = false;
//     }
//   }

//   const WebCamElement = (
//     <div>
//       {isWebcamOn ? (
//         <div
//           className={`m-auto w-80 h-60 ${
//             okk ? "border-4 border-green-500" : "border-4 border-red-500"
//           }`}
//         >
//           <Webcam
//             className="p-px"
//             audio={!isWebcamOn} // Toggle audio on/off based on the webcam state
//             // height={height}
//             ref={webcamRef}
//             screenshotFormat="image/jpeg"
//             // width={width}
//             // videoConstraints={videoConfig}
//           />
//         </div>
//       ) : (
//         <div className=" m-auto w-80 h-60 border-2 bg-black border-black flex items-center justify-center text-white">
//           Webcam is turned off!!
//         </div>
//       )}

//       <button onClick={toggleWebcam}>
//         {isWebcamOn ? "Turn Off Webcam" : "Turn On Webcam"}
//       </button>
//     </div>
//   );
//   return (
//     <>
//       {formik.values.facePhoto === "" ? (
//         WebCamElement
//       ) : (
//         <div className=" m-auto w-80 h-60 ">
//           <img src={formik.values.facePhoto} alt="" />
//         </div>
//       )}

//       <div className=" w-fit m-auto">
//         {formik.values.facePhoto !== "" ? (
//           <button
//             className="m-4 p-3 rounded-full bg-gray-400 hover:bg-gray-700 border-gray-400 border-2 text-white font-bold h-14 w-14 flex items-center justify-center"
//             onClick={(e) => {
//               e.preventDefault();
//               formik.setValues({ ...formik.values, facePhoto: "" });
//             }}
//           >
//             <CachedIcon fontSize="large" />
//           </button>
//         ) : (
//           <button
//             className="m-4 p-3 rounded-full bg-gray-400 hover:bg-gray-700 border-gray-400 border-2 text-white font-bold h-14 w-14 flex items-center justify-center"
//             onClick={(e) => {
//               e.preventDefault();
//               capture();
//             }}
//           >
//             <CameraAltIcon fontSize="large" />
//           </button>
//         )}
//       </div>
//     </>
//   );
// };

// export default FacePhoto;
