import React, { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, CircularProgress, Fab } from "@mui/material";
import { green } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import firebaseApp from "../config/firebaseConfig";
import { toast } from "react-toastify";

const ImageUpload = ({ formik }) => {
  const [files, setFiles] = useState([]);
  const [fileURLs, setFileURLs] = useState([]);

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
    }
  };

  const fileSelectedHandler = (e) => {
    const selectedFiles = e.target.files;

    if (selectedFiles.length > 0) {
      const addedFiles = [...files];

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        addedFiles.push({ file, id: Date.now() + i });
        console.log("Uploaded file: " + file.name);
      }

      setFiles(addedFiles);
    }
  };

  const handleFileUpload = async (file) => {
    try {
      // toast.info("Uploading Image...", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 2000,
      // });

      const storage = getStorage(firebaseApp);
      const fileRef = ref(storage, "facePhoto/" + file.name);
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on("state_changed", (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      });

      await uploadTask;

      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      setFileURLs((prevURLs) => [...prevURLs, downloadURL]);
      // TODO: save the downloadURL to the database
      console.log("File available at", downloadURL);
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      console.error("Upload error:", error);
      toast.error("Error while Uploading Image...", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const deleteImg = (id) => {
    const updatedFiles = files.filter((item) => item.id !== id);
    setFiles(updatedFiles);
  };

  formik.values.chestXRay = fileURLs;

  return (
    <>
      <input
        type="file"
        onChange={fileSelectedHandler}
        accept="image/* .pdf"
        multiple
      />
      <ImageList
        sx={{
          width: "auto",
          height: "auto",
          maxHeight: 450,
          transform: "translateZ(0)",
        }}
        rowHeight={200}
        gap={1}
      >
        {files.map((item) => (
          <ImageListItem key={item.id} cols={1} rows={1}>
            <img
              src={URL.createObjectURL(item.file)}
              alt={item.file.name}
              loading="lazy"
            />
            <ImageListItemBar
              sx={{
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                  "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
              }}
              title={item.file.name}
              position="top"
              actionIcon={
                <IconButton
                  sx={{ color: "white" }}
                  aria-label={`star ${item.file.name}`}
                  onClick={() => deleteImg(item.id)}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              }
              actionPosition="right"
            />
          </ImageListItem>
        ))}
      </ImageList>
      {/* <div className="flex p-2">
        <Button
          variant="contained"
          onClick={async () => {
            for (const file of files) {
              await handleFileUpload(file.file);
            }
          }}
          disabled={files.length === 0}
          mt={2}
        >
          Upload Images
        </Button>
      </div> */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ m: 1, position: "relative" }}>
          <Fab
            aria-label="save"
            color="primary"
            sx={buttonSx}
            onClick={async () => {
              handleButtonClick();
              for (const file of files) {
                await handleFileUpload(file.file);
              }
            }}
            disabled={files.length === 0}
          >
            {success ? <CheckIcon /> : <CloudUploadIcon />}
          </Fab>
          {loading && (
            <CircularProgress
              size={68}
              sx={{
                color: green[500],
                position: "absolute",
                top: -6,
                left: -6,
                zIndex: 1,
              }}
            />
          )}
        </Box>
        <Box sx={{ m: 1, position: "relative" }}>
          <Button
            variant="contained"
            sx={buttonSx}
            // disabled={loading}
            onClick={async () => {
              handleButtonClick();
              for (const file of files) {
                await handleFileUpload(file.file);
              }
            }}
            disabled={files.length === 0}
          >
            Upload
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </Box>
      {/* {fileURLs.length > 0 && (
        <div>
          <h2>Uploaded URLs:</h2>
          <ul>
            {fileURLs.map((url, index) => (
              <li key={index}>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-clip overflow-hidden ..."
                >
                  {url}
                </a>
                <br />
                <br />
              </li>
            ))}
          </ul>
        </div>
      )} */}
    </>
  );
};

export default ImageUpload;
