import Webcam from "react-webcam";
import { useFaceDetection } from "react-use-face-detection";
import FaceDetection from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";
import React, { useCallback } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CachedIcon from "@mui/icons-material/Cached";
import { Button, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const width = 300;
const height = 240;

const SelfieValidate = ({
  formik,
  isCamEnable,
  handleHideWebcam,
  handleSelfieRetake,
}) => {
  let msg = "Loading...";
  // console.log("isCamEnable " + isCamEnable);
  const { webcamRef, boundingBox, isLoading, detected, facesDetected } =
    useFaceDetection({
      faceDetectionOptions: {
        model: "short",
        minDetectionSize: 0.2,
      },
      faceDetection: new FaceDetection.FaceDetection({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      }),
      camera: ({ mediaSrc, onFrame }) =>
        new Camera(mediaSrc, {
          onFrame,
          width,
          height,
        }),
    });

  let okk = false;
  // console.log(okk);

  if (facesDetected === 1) {
    // check if the face is in center of the frame (xCenter and yCenter)
    // check if the face is close or not (width and height)
    const { xCenter, yCenter, width, height } = boundingBox[0];
    if (xCenter > 0.2 && xCenter < 0.6 && yCenter > 0.3 && yCenter < 0.6) {
      if (width > 0.25 && height > 0.28) {
        msg = `Face is valid`;
        okk = true;
      } else {
        msg = `Face is too small`;
        okk = false;
      }
    } else {
      msg = `Face not centered`;
      okk = false;
    }
  }

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (okk) formik.setValues({ ...formik.values, facePhoto: imageSrc });
    // console.log("formic facephoto "+ formik.values.facePhoto);
  });

  const videoConfig = {
    width: { ideal: 300 },
    height: { ideal: 240 },
    frameRate: { ideal: 15, max: 30 },
  };

  const webcamComponent = isCamEnable ? (
    <Webcam
      className="m-1"
      audio={false}
      height={height}
      ref={webcamRef}
      screenshotFormat="image/jpeg"
      width={width}
      videoConstraints={videoConfig}
    />
  ) : null;

  return (
    <>
      <div
        className="webcam-img mx-auto mt-9 w-fit"
        style={{
          position: "relative",
          border: okk ? "4px solid green" : "4px solid red",
        }}
      >
        {formik.values.facePhoto === "" ? (
          webcamComponent
        ) : (
          <img
            src={formik.values.facePhoto}
            className="m-1"
            width={width}
            height={height}
            alt=""
          />
        )}
      </div>
      <div className=" w-fit m-auto">
        {formik.values.facePhoto !== "" ? (
          <Button
            sx={{
              margin: "1rem",
              padding: "0.75rem",
              borderRadius: "50%",
              border: "2px solid #718096",
              width: "3.5rem",
              height: "3.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "#718096",
              color: "white",
              fontWeight: "bolder",
            }}
            onClick={handleSelfieRetake}
          >
            <CachedIcon fontSize="large" />
          </Button>
        ) : (
          <Button
            sx={{
              margin: "1rem",
              padding: "0.75rem",
              borderRadius: "50%",
              width: "3.5rem",
              height: "3.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontWeight: "bold",
            }}
            variant="contained"
            color={okk ? "success" : "error"}
            onClick={(e) => {
              e.preventDefault();
              capture();
            }}
          >
            <CameraAltIcon fontSize="large" />
          </Button>
        )}
      </div>

      <div className="text-center">
        {facesDetected > 1 ? (
          <Typography variant="h6">
            <ErrorIcon fontSize="medium" className="text-red-700" /> Only one
            face is allowed in the frame
          </Typography>
        ) : facesDetected === 0 ? (
          <Typography variant="h6">
            <ErrorIcon fontSize="medium" className="text-red-700" /> No face
            detected
          </Typography>
        ) : (
          <Typography variant="h6">
            <CheckCircleIcon fontSize="medium" className="text-green-700 " />{" "}
            Face Detected
          </Typography>
        )}
        <Typography variant="h6">
          {okk ? (
            <CheckCircleIcon fontSize="medium" className="text-green-700 " />
          ) : (
            <ErrorIcon fontSize="medium" className="text-red-700" />
          )}{" "}
          {msg}
        </Typography>
      </div>
    </>
  );
};

export default SelfieValidate;
