import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import firebaseApp from "../config/firebaseConfig";
import { toast } from "react-toastify";

function VoiceRecorder({ formik }) {
  const [recording, setRecording] = useState(false);
  const [paused, setPaused] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    let interval;

    if (recording || paused) {
      interval = setInterval(() => {
        setDuration((prevDuration) => prevDuration + 100); // increment by 100 milliseconds
      }, 100);
    }

    return () => {
      clearInterval(interval);
    };
  }, [recording, paused]);

  const formatDuration = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = ((milliseconds % 60000) / 1000).toFixed(1);
    return `${minutes}:${seconds}`;
  };

  const startRecording = () => {
    console.log("startRecording");
    if (!navigator.mediaDevices) {
      console.log("No media devices available");
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = handleDataAvailable;
        mediaRecorderRef.current.onstop = handleStop;
        mediaRecorderRef.current.start();
        setRecording(true);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const stopRecording = async () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      console.log("stopRecording");
      mediaRecorderRef.current.stop();
      setRecording(false);
      setPaused(false);
      setDuration(0);
    }
  };

  const handleDataAvailable = (event) => {
    console.log("handleDataAvailable");
    if (event.data.size > 0) {
      chunksRef.current.push(event.data);
    }
  };

  const handleStop = async () => {
    console.log("handleStop");
    if (chunksRef.current.length) {
      await saveRecording();
    }
  };

  const saveRecording = async () => {
    console.log("saveRecording");
    const blob = new Blob(chunksRef.current, { type: "audio/webm" });
    const url = URL.createObjectURL(blob);

    // uploading the blob to firebase storage and getting the url
    const storage = getStorage(firebaseApp);
    const fileRef = ref(storage, "voiceSample/" + formik.values.email);
    const uploadTask = uploadBytesResumable(fileRef, blob);

    uploadTask.on("state_changed", (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log("Upload is " + progress + "% done");
    });

    await uploadTask;

    toast.success("Voice sample uploaded successfully");

    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
    formik.setValues({ ...formik.values, voiceSample: downloadURL });

    // const downloadLink = document.createElement("a");
    // downloadLink.href = url;
    // downloadLink.download = "recorded_audio.webm";
    // downloadLink.click();

    // URL.revokeObjectURL(url);

    chunksRef.current = [];
  };

  return (
    <Container>
      <div className="text-center">
        <Box
          sx={{
            backgroundColor: "white",
            padding: "16px",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
          }}
        >
          <Typography variant="body1" paragraph>
            Please read the following script out loud and record your voice:
          </Typography>
          <Typography variant="h4" paragraph>
            अ... आ... इ... ई... उ... ऊ... ए... ऐ... ओ... औ... अं... अंह... ख..
            खा. झ. ज. थ. प. फह. क्ष. त्र.
          </Typography>
          <Typography variant="h6" gutterBottom mt={2}>
            Duration: {formatDuration(duration)}
          </Typography>
          <div className="mt-5">
            <Button
              variant="contained"
              color="success"
              onClick={startRecording}
              disabled={recording}
              startIcon={<PlayArrowIcon />}
            >
              Start Recording
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={stopRecording}
              disabled={!recording}
              startIcon={<StopIcon />}
            >
              Stop Recording
            </Button>
          </div>
        </Box>
      </div>
    </Container>
  );
}

export default VoiceRecorder;
