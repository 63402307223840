import * as React from "react";
import {
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  TextField,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const today = dayjs();

const BasicInfo = ({ formik }) => {
  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    formik.setValues({ ...formik.values, [name]: value });
  };

  const renderTextField = (name, label, type) => (
    <FormControl fullWidth>
      <TextField
        id={name}
        name={name}
        type={type}
        value={formik.values[name]}
        onChange={handleInputChange}
        label={label}
      />
      {formik.errors[name] ? (
        <FormHelperText style={{ color: "red" }}>
          {formik.errors[name]}
        </FormHelperText>
      ) : null}
    </FormControl>
  );

  const renderRadioField = (name, label) => (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={name}
        onChange={handleInputChange}
        value={formik.values[name]}
      >
        <FormControlLabel value="YES" control={<Radio />} label="YES" />
        <FormControlLabel value="NO" control={<Radio />} label="NO" />
      </RadioGroup>
      {formik.errors[name] ? (
        <FormHelperText style={{ color: "red" }}>
          {formik.errors[name]}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
  const handleDateTimeChange = (newDateTime, field) => {
    const dayjsDateTime = dayjs(newDateTime);
    formik.setValues({ ...formik.values, dateTime: dayjsDateTime });
  };

  return (
    <React.Fragment>
      <Typography variant="h6" sx={{ marginBottom: "20px" }}>
      Fill Your basic information
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {renderRadioField("isConsentObtained", "Is consent obtained?")}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("internName", "Intern Name", "text")}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("hospitalName", "Hospital", "text")}
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {renderTextField("patientId", "Patient ID", "text")}
        </Grid> */}
        <Grid item xs={12} sm={6}>
          {renderTextField("age", "Age", "number")}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formik.values.gender}
              label="Gender"
              name="gender"
              onChange={handleInputChange}
            >
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
            {formik.touched["gender"] && Boolean(formik.errors["gender"]) ? (
              <FormHelperText style={{ color: "red" }}>
                {formik.errors["gender"]}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem>
              <DateTimePicker
                id="dateTime"
                name="dateTime"
                control={formik.control}
                value={dayjs(formik.values.dateTime)}
                defaultValue={today}
                views={["year", "month", "day", "hours", "minutes"]}
                textField={(props) => (
                  <TextField
                    {...props}
                    error={formik.errors["dateTime"]}
                    helperText={formik.errors["dateTime"]}
                  />
                )}
                onChange={(newDate) =>
                  handleDateTimeChange(newDate, "dateTime")
                }
                label="Date"
              />
            </DemoItem>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          {renderRadioField("isPatientOnTreatment", "Is patient on treatment?")}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("hbValue", "HB Value")}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("esr", "ESR")}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("lymphocyteValue", "Lymphocyte Value")}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("diagnosis", "Diagnosis")}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("clinicalInfo1", "Other ClinicalInfo")}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BasicInfo;
