import React from "react";
import ImageUpload from "../VoiceSample/ImageUpload";
import InvestigationReports from "../VoiceSample/InvestigationReports";
import ChestXRay from "../VoiceSample/ChestXRay";
import OtherDoc from "../VoiceSample/OtherDoc";

import { Divider, Chip, Typography } from "@mui/material";

const DocUplaod = ({ formik }) => {
  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ textAlign: "center" }}>
        Medical Diagnosis/Treatment files
      </Typography>
      <Divider>
        <Chip label="Upload Treatment Doc" />
      </Divider>
      <ImageUpload formik={formik} />

      <br />
      <br />
      <Divider>
        <Chip label="Upload Investigation reports" />
      </Divider>
      <InvestigationReports formik={formik} />

      <br />
      <br />
      <Divider>
        <Chip label="Upload Chest x-ray" />
      </Divider>
      <ChestXRay formik={formik} />

      <br />
      <br />
      <Divider>
        <Chip label="Upload Any other imp doc" />
      </Divider>
      <OtherDoc formik={formik} />
    </>
  );
};

export default DocUplaod;
