import React from "react";
import {
  Box,
  Typography,
  Link,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";

const Concent = ({ formik }) => {
  return (
    <>
      <Box className="mb-6">
        <Typography variant="h5" gutterBottom sx={{ textAlign: "center" }}>
          Important Disclosure & User Consent
        </Typography>
        <Typography variant="body" gutterBottom>
          Before we proceed, I would like to confirm that you understand and
          agree to the terms of Voice Collection for Disease Screening. Your
          participation is completely voluntary, and you have the right to
          decline or withdraw your consent at any time without any negative
          consequences.{" "}
        </Typography>
      </Box>
      <Box className="mb-4">
        <Typography variant="body">
          By continuing with this activity and providing your verbal consent,
          you explicitly acknowledge and agree to the following:
        </Typography>
        <ul className="list-disc list-inside mt-2">
          <li>
            The voice sample analysis will be published without my name
            attached. Every effort will be made to ensure I cannot be
            identified.
          </li>
          <li>
            The voice sample may be published in the monthly printed edition of
            suitable journals.
          </li>
          <li>
            If published in the printed edition, the same material will also be
            used in the electronic edition. Photographic material may be used on
            the cover of the journal. The material will not be used out of
            context, e.g., for advertising or packaging other products.
          </li>
          <li>
            I also give consent for voice samples to be used in other
            publications (including books, journals, CD-ROMs, and online and
            Internet publications) that may approach Clinical and Experimental
            analysis so long as the voice sample is not used out of context. For
            example, photographs will not be used in advertising or packaging
            other products.
          </li>
        </ul>
      </Box>
      <Box>
        <Typography variant="body">
          If you have any questions or concerns, please don't hesitate to ask.
          However, if you are comfortable with the terms and would like to
          proceed, please indicate your agreement by accepting the Terms and
          Conditions
        </Typography>
        <br />
        <Link href="#">Terms and Conditions.</Link>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.termsAndConditions}
                onChange={(event) => {
                  // setIsChecked(event.target.checked);
                  // Check if the checkbox is checked and set your hook to true
                  if (event.target.checked) {
                    formik.setValues({
                      ...formik.values,
                      termsAndConditions: true,
                    });
                  }
                }}
                color="success"
                name="termsAndConditions"
              />
            }
            label="I Accept The Terms Ands Conditions"
          />
          {/* {formik.errors["termsAndConditions"] ? (
            <FormHelperText style={{ color: "red" }}>
              {formik.errors["termsAndConditions"]}
            </FormHelperText>
          ) : null} */}
        </FormGroup>
      </Box>
    </>
  );
};

export default Concent;
