import * as Yup from "yup";
import dayjs from "dayjs";

const initialValues = {
  termsAndConditions: false,
  isConsentObtained: "",
  internName: "",
  hospitalName: "",
  dateTime: dayjs(),
  age: "",
  gender: "",
  hbValue: "",
  esr: "",
  lymphocyteValue: "",
  diagnosis: "",
  clinicalInfo1: "",
  facePhoto: "",
  voiceSample: "",
  isPatientOnTreatment: "",
  treatmentDoc: [],
  investigationReports: [],
  chestXRay: [],
  otherDoc: [],
};

const validationSchema = Yup.object().shape({
  termsAndConditions: Yup.boolean()
    .oneOf([true], "Please accept the terms and conditions.")
    .required("Please accept the terms and conditions."),
  isConsentObtained: Yup.string().required("Required*"),
  internName: Yup.string()
    .min(3, "Name should atleast have 3 characters.")
    .max(30, "Name should not exceed 30 characters.")
    .required("Required*"),
  hospitalName: Yup.string()
    .min(3, "Hospital Name should atleast have 3 characters.")
    .max(30, "Hospital Name should not exceed 30 characters.")
    .required("Required*"),
  age: Yup.number()
    .min(1, "Age should be atleast 15years.")
    .max(100, "Age should not exceed 100 years")
    .required("Required*"),
  // patientId: Yup.string().required("Patient ID is required"),
  gender: Yup.string().required("Required*"),
  dateTime: Yup.string().required("Required*"),
  hbValue: Yup.string(),
  esr: Yup.string(),
  lymphocyteValue: Yup.string(),
  diagnosis: Yup.string(),
  clinicalInfo1: Yup.string(),
  facePhoto: Yup.string().required("Face Photo is required"),
  voiceSample: Yup.string().required("Voice Sample is required"),
  isPatientOnTreatment: Yup.string().required(
    "Is consent obtained is required"
  ),
});

export default initialValues;
export { validationSchema };
